import { useStateValue } from '../state/state'

import classNames from 'classnames'

import Header from './Header'
import Progress from './Progress'

const Layout = ({ children }) => {
  const [{ currentStep, totalSteps }] = useStateValue()

  return (
    <div
      className={classNames(['wrapper'], {
        'wrapper--sticky-progress':
          currentStep === 0 || currentStep === totalSteps,
      })}
    >
      <Header />
      {children}
      <Progress current={currentStep} total={totalSteps} />
    </div>
  )
}

export default Layout
