import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { StateProvider } from './state/state'
import { reducer, initialState } from './state/reducer'

import SEO from './layout/SEO'
import Home from './pages/Home'
import Card from './components/Card'

import './styles/main.scss'

function App() {
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <SEO />
        <div className="App">
          <Switch>
            <Route path="/card/:id">
              <Card />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </StateProvider>
  )
}

export default App
