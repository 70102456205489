import React from 'react'

import sourdough from '../../static/images/questions/sourdough.svg'
import coffee from '../../static/images/questions/coffee.svg'
import tiger from '../../static/images/questions/tiger.svg'

import gingerbread1 from '../../static/images/questions/gingerbread1.gif'
import holly2 from '../../static/images/questions/holly2.gif'
import mittens1 from '../../static/images/questions/mittens1.gif'
import snow1 from '../../static/images/questions/snow1.gif'

const trends = {
  sourdough: sourdough,
  coffee: coffee,
  tiger: tiger,
}

const themes = {
  gingerbread1: gingerbread1,
  holly2: holly2,
  mittens1: mittens1,
  snow1: snow1,
}

const Input = ({ type, setInputValue, currentRadio, inputValue }) => {
  const validate = (e) => {
    if (e.target.value.length > 0) {
      setInputValue(e.target.value)
    } else {
      setInputValue('')
    }
  }

  const validateRadio = (e) => {
    setInputValue(e.target.value)
  }

  switch (type) {
    case 'textarea':
      return (
        <fieldset className={'input'}>
          <textarea
            className={'input__textarea'}
            name="input"
            placeholder={'Type first name here'}
            onBlur={validate}
            onInput={validate}
            maxLength={32}
            value={inputValue}
          />
        </fieldset>
      )
    case 'radio_0':
      let choices = [
        {
          label: 'Sourdough all the way',
          id: 'sourdough',
        },
        {
          label: 'Frothy coffee FTW',
          id: 'coffee',
        },
        {
          label: 'Tiger King rules',
          id: 'tiger',
        },
      ]

      return (
        <fieldset className={'input input--radio'}>
          {choices.map(({ label, id }, index) => {
            return (
              <label key={index} className={'input__label'}>
                {trends[id] && (
                  <img className={'input__image'} src={trends[id]} alt="" />
                )}
                {label}
                <input
                  className={'input__radio'}
                  type="radio"
                  name="input"
                  value={id}
                  onBlur={validateRadio}
                  onInput={validateRadio}
                  onChange={validateRadio}
                  checked={id === currentRadio}
                ></input>
                <span className={'input__checkmark'} />
              </label>
            )
          })}
        </fieldset>
      )
    case 'radio_1':
      let choices1 = [
        {
          label: 'photography',
          id: 'photography',
        },
        {
          label: 'yoga',
          id: 'yoga',
        },
        {
          label: 'cooking',
          id: 'cooking',
        },
        {
          label: 'running',
          id: 'running',
        },
        {
          label: ' binge-streaming',
          id: 'binge-streaming',
        },
        {
          label: 'stress eating',
          id: 'stress eating',
        },
        {
          label: 'spending time with family',
          id: 'spending time with family',
        },
        {
          label: 'the great outdoors',
          id: 'the great outdoors',
        },
        {
          label: 'being alone',
          id: 'being alone',
        },
        {
          label: 'working in my pajamas',
          id: 'working in my pajamas',
        },
      ]

      return (
        <fieldset className={'input input--radio2'}>
          {choices1.map(({ label, id }, index) => {
            return (
              <label key={index} className={'input__label input__label2'}>
                <input
                  className={'input__radio2'}
                  type="radio"
                  name="input"
                  value={id}
                  onBlur={validateRadio}
                  onInput={validateRadio}
                  onChange={validateRadio}
                  checked={id === currentRadio}
                ></input>
                <span className={'input__checkmark2'}>{label}</span>
              </label>
            )
          })}
        </fieldset>
      )
    case 'radio_2':
      let choices2 = [
        {
          label: 'I reconnected with old friends in new ways.',
          id: 'I reconnected with old friends in new ways.',
        },
        {
          label: 'I learned how resilient I really am!',
          id: 'I learned how resilient I really am!',
        },
        {
          label: 'I made some healthy changes in my life.',
          id: 'I made some healthy changes in my life.',
        },
        {
          label: 'I tried something new.',
          id: 'I tried something new.',
        },
        {
          label: 'I learned more about social justice issues.',
          id: 'I learned more about social justice issues',
        },
        {
          label: 'I did not spontaneously combust.',
          id: 'I did not spontaneously combust.',
        },
        {
          label: "I learned that it's OK to acknowledge my limits",
          id: "I learned that it's OK to acknowledge my limits",
        },
        {
          label: 'I learned the value of self-care.',
          id: 'I learned the value of self-care.',
        },
      ]

      return (
        <fieldset className={'input input--radio2'}>
          {choices2.map(({ label, id }, index) => {
            return (
              <label key={index} className={'input__label input__label2'}>
                <input
                  className={'input__radio2'}
                  type="radio"
                  name="input"
                  value={id}
                  onBlur={validateRadio}
                  onInput={validateRadio}
                  onChange={validateRadio}
                  checked={id === currentRadio}
                ></input>
                <span className={'input__checkmark2 input__checkmark2--long'}>
                  {label}
                </span>
              </label>
            )
          })}
        </fieldset>
      )
    case 'number':
      return (
        <fieldset className="input input--number">
          <input
            className={'input__number'}
            onBlur={validate}
            onInput={validate}
            name="input"
            placeholder={'Numbers only'}
            type="number"
            min="0"
            max="999"
            value={inputValue}
          ></input>
          <label htmlFor="input" className={'input__label'}>
            {'Minutes'}
          </label>
        </fieldset>
      )
    case 'radio_3':
      let choices3 = [
        {
          label: 'American Red Cross',
          id: 'American Red Cross',
        },
        {
          label: 'Feeding America',
          id: 'Feeding America',
        },
        {
          label: 'Salvation Army',
          id: 'Salvation Army',
        },
        {
          label: 'Habitat for Humanity',
          id: 'Habitat for Humanity',
        },
      ]

      return (
        <fieldset className={'input input--radio2'}>
          {choices3.map(({ label, id }, index) => {
            return (
              <label key={index} className={'input__label input__label2'}>
                <input
                  className={'input__radio2'}
                  type="radio"
                  name="input"
                  value={id}
                  onBlur={validateRadio}
                  onInput={validateRadio}
                  onChange={validateRadio}
                  checked={id === currentRadio}
                ></input>
                <span className={'input__checkmark2'}>{label}</span>
              </label>
            )
          })}
        </fieldset>
      )
    case 'radio_4':
      let choices4 = [
        {
          label: 'Hope',
          id: 'Hope',
        },
        {
          label: 'Change',
          id: 'Change',
        },
        {
          label: 'Collaboration',
          id: 'Collaboration',
        },
        {
          label: 'Joy',
          id: 'Joy',
        },
        {
          label: 'Fierce',
          id: 'Fierce',
        },
        {
          label: 'Fearless',
          id: 'Fearless',
        },
        {
          label: 'Empowered',
          id: 'Empowered',
        },
        {
          label: 'Empathy',
          id: 'Empathy',
        },
      ]

      return (
        <fieldset className={'input input--radio2'}>
          {choices4.map(({ label, id }, index) => {
            return (
              <label key={index} className={'input__label input__label2'}>
                <input
                  className={'input__radio2'}
                  type="radio"
                  name="input"
                  value={id}
                  onBlur={validateRadio}
                  onInput={validateRadio}
                  onChange={validateRadio}
                  checked={id === currentRadio}
                ></input>
                <span className={'input__checkmark2'}>{label}</span>
              </label>
            )
          })}
        </fieldset>
      )
    case 'radio_5':
      let choices5 = [
        {
          label: 'Gingerbread dreams',
          id: 'gingerbread1',
        },
        {
          label: 'Festive holly',
          id: 'holly2',
        },
        {
          label: 'Warm mittens',
          id: 'mittens1',
        },
        {
          label: 'Winter wonderland',
          id: 'snow1',
        },
      ]

      return (
        <fieldset className={'input input--radio'}>
          {choices5.map(({ label, id }, index) => {
            return (
              <label key={index} className={'input__label'}>
                {themes[id] && (
                  <img className={'input__image'} src={themes[id]} alt="" />
                )}
                {label}
                <input
                  className={'input__radio'}
                  type="radio"
                  name="input"
                  value={id}
                  onBlur={validateRadio}
                  onInput={validateRadio}
                  onChange={validateRadio}
                  checked={id === currentRadio}
                ></input>
                <span className={'input__checkmark'} />
              </label>
            )
          })}
        </fieldset>
      )
    default:
      return null
  }
}

export default Input
