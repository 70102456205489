import React from 'react'
import { useParams } from 'react-router-dom'

// import axios from 'axios'

import { useStateValue } from '../../state/state'

import Header from '../../layout/Header'
import CardLanding from '../CardLanding'

/*
  name - string, trimmed to first name
  theme - int, radio list - controls background
  activity - string, one word
  trend - int, radio list
  commute_time - int, calculation for time saved
  memory - string, 200 characters max
  positive - string, one word
  charity - int, radio list
*/

const Layout = ({ children }) => {
  return (
    <div className="card">
      <Header />
      {children}
    </div>
  )
}

const Card = (props) => {
  const { id } = useParams()
  const [{ user }] = useStateValue()

  console.log(user)

  // useEffect(() => {
  //   if (user.name?.length > 0) {
  //     axios
  //       .post(
  //         'https://rapp-fcn-holidaycard2020.azurewebsites.net/api/GoodListEntry?code=Psdi1pDKZhCSCThV1lFRjRj0CfW7pbdvsEuRNBvaYN4zD4SlkwQIvg==',
  //         {
  //           name: user.name,
  //           quarenTrend: user.trend,
  //           joys: user.hobby,
  //           gratefulThing: user.memory,
  //           commuteTime: user.commute,
  //           charitableOrganizationToDonate: user.charity,
  //           positiveWord: user.word,
  //           favoriteTheme: user.theme,
  //           emailAddress: user.email,
  //         }
  //       )
  //       .then(function (response) {
  //         // console.log(response)
  //       })
  //       .catch(function (error) {
  //         // console.log(error)
  //       })
  //   }
  // }, [user])

  return (
    <Layout>
      <CardLanding {...props.card} {...JSON.parse(atob(id))} />
    </Layout>
  )
}

export default Card
