import { UPDATE_CURRENT_STEP, UPDATE_USER } from './actionTypes'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        currentStep: Math.max(Math.min(action.value, state.totalSteps), 0),
      }
    case UPDATE_USER:
      return {
        ...state,
        user: { ...action.value },
      }
    default:
      return state
  }
}

export const initialState = {
  currentStep: 0,
  totalSteps: 9,
  user: {
    name: '',
    trend: '',
    hobby: '',
    memory: '',
    commute: '',
    charity: '',
    word: '',
    theme: '',
    email: '',
    nameEntered: false,
  },
}
