import React from 'react'

import domtoimage from 'dom-to-image-improved'
import gifshot from 'gifshot'

import {
  // EmailShareButton,
  // EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  // LinkedinShareButton,
  // LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import CTA from '../CTA'

import logo from '../../static/images/RAPP_Logo_RGB.png'
import greetingImage from '../../static/images/card/greeting-text.png'
import gingerbread from '../../static/images/card/gingerbread.gif'
import snow from '../../static/images/card/snow.gif'
import holly from '../../static/images/card/holly.gif'
import mittens from '../../static/images/card/mittens.gif'

import mittens_coffee from '../../static/images/card/mittens_coffee.png'
import mittens_sourdough from '../../static/images/card/mittens_sourdough.png'
import mittens_tiger from '../../static/images/card/mittens_tiger.png'

import snow_coffee from '../../static/images/card/snow_coffee.png'
import snow_sourdough from '../../static/images/card/snow_sourdough.png'
import snow_tiger from '../../static/images/card/snow_tiger.png'

import holly_coffee from '../../static/images/card/holly_coffee.png'
import holly_sourdough from '../../static/images/card/holly_sourdough.png'
import holly_tiger from '../../static/images/card/holly_tiger.png'

import gingerbread_coffee from '../../static/images/card/gingerbread_coffee.png'
import gingerbread_sourdough from '../../static/images/card/gingerbread_sourdough.png'
import gingerbread_tiger from '../../static/images/card/gingerbread_tiger.png'

const icons = {
  mittens1: {
    coffee: mittens_coffee,
    sourdough: mittens_sourdough,
    tiger: mittens_tiger,
  },
  snow1: {
    coffee: snow_coffee,
    sourdough: snow_sourdough,
    tiger: snow_tiger,
  },
  holly2: {
    coffee: holly_coffee,
    sourdough: holly_sourdough,
    tiger: holly_tiger,
  },
  gingerbread1: {
    coffee: gingerbread_coffee,
    sourdough: gingerbread_sourdough,
    tiger: gingerbread_tiger,
  },
}

const trends = {
  coffee: 'Frothy coffee, yum',
  tiger: 'watching Tiger King, baby',
  sourdough: 'perfecting my sourdough game',
}

const CardImage = ({
  theme,
  bgImage,
  id,
  name,
  memory,
  trend,
  hobby,
  word,
  commute,
  classes = '',
}) => {
  return (
    <div id={id} className={`download download--${theme} download--${classes}`}>
      <img src={bgImage} className="download__image" alt="" />
      <div className={'download__image__rapp'}>
        <img
          src={greetingImage}
          className="download__image__rapp__text"
          alt="Happy holidays from Rapp"
        />
        <img
          src={logo}
          className="download__image__rapp__logo"
          alt="Rapp Logo"
        />
      </div>
      <div className={`download__table download__table--${theme}`}>
        <div className={'box1'}>
          <h2 className={'download__name'}>{`${name}'s`}</h2>
          <h3 className={'download__large-text'}>GOOD LIST</h3>
        </div>
        <div className={'box2'}>
          <h4 className={`download__header download__header--${theme}`}>
            Because even 2020 had its silver linings
          </h4>
          <h5 className={'download__h6 download__h6--black'}>
            As the year draws to a close, here are a few of the good things to
            remember about 2020.
          </h5>
        </div>
        <div className={'box3'}>
          <h6 className={'download__h6 download__h6--skinny'}>
            One thing I'm really grateful for this year:
          </h6>
          <h6 className={'download__h6 download__h6--black'}>{memory}</h6>
        </div>
        <div className={'box4'}>
          <img
            src={icons[theme][trend]}
            className={`icon icon--${classes}`}
            alt=""
          />
          <h6 className={'download__h6'}>My favorite Quarantrend was:</h6>
          <h6 className={'download__h6 download__h6--black'}>
            {trends[trend]}
          </h6>
        </div>
        <div className={'box5'}>
          <h6 className={'download__h6 download__h6--skinny'}>
            In 2020, I couldn't get enough of:
          </h6>
          <h6 className={'download__h6 download__h6--black'}>{hobby}</h6>
        </div>
        <div className={'box6'}>
          <h6 className={'download__h6 download__h6--skinny-2'}>
            My word for 2021:
          </h6>
          <h6 className={'download__h6 download__h6--black'}>{word}</h6>
        </div>
        <div className={'box7'}>
          <h6 className={'download__h6 download__h6--skinny-2'}>
            Each day I didn't have to commute =
          </h6>
          <h6
            className={
              'download__h6 download__h6--black download__h6--black--large'
            }
          >
            {parseInt(commute) * 2}
          </h6>
          <h6 className={'download__h6 download__h6--skinny'}>
            minutes of my life back!
          </h6>
        </div>
      </div>
    </div>
  )
}

const CardLanding = (props) => {
  const { name, theme, memory, trend, hobby, word, commute, charity } = props

  let bgImage

  switch (theme) {
    case 'gingerbread1':
      bgImage = gingerbread
      break
    case 'holly2':
      bgImage = holly
      break
    case 'mittens1':
      bgImage = mittens
      break
    case 'snow1':
      bgImage = snow
      break
    default:
      bgImage = gingerbread
      break
  }

  return (
    <section>
      <div className="container container--section container--card">
        <div className="card__copy">
          <h1 className="heading-1 card__heading">{`You made it,\n${name}!`}</h1>
          {/* <div className={'card__image'}>
            <CardImage
              name={name}
              id={'visible'}
              theme={theme}
              memory={memory}
              bgImage={bgImage}
              classes={'fluid'}
              trend={trend}
              hobby={hobby}
              word={word}
              commute={commute}
            />
          </div> */}
          {/* <div className={'holder'}>
            <CardImage
              name={name}
              id={'download1'}
              theme={theme}
              memory={memory}
              bgImage={`${process.env.PUBLIC_URL}/${theme}/1.gif`}
              trend={trend}
              hobby={hobby}
              word={word}
              commute={commute}
            />
          </div>
          <div className={'holder'}>
            <CardImage
              name={name}
              id={'download2'}
              theme={theme}
              memory={memory}
              bgImage={`${process.env.PUBLIC_URL}/${theme}/2.gif`}
              trend={trend}
              hobby={hobby}
              word={word}
              commute={commute}
            />
          </div>
          <div className={'holder'}>
            <CardImage
              name={name}
              id={'download3'}
              theme={theme}
              memory={memory}
              bgImage={`${process.env.PUBLIC_URL}/${theme}/3.gif`}
              trend={trend}
              hobby={hobby}
              word={word}
              commute={commute}
            />
          </div> */}
        </div>
        <div className="card__copy">
          <p className="paragraph card__paragraph">
            Download your Good List to keep, plus share it with your friends to
            give them a chance to make one too!
          </p>
          <p className="paragraph card__paragraph" style={{ marginBottom: 0 }}>
            {`When you share, RAPP will also make a donation to ${charity}. Happy holidays, and stay fierce!`}
          </p>
          <div className="share-buttons">
            <FacebookShareButton
              quote={`Even 2020 had its silver linings! Check out my personal #RAPPGoodList for 2020, then make one of your own, Happy holidays!`}
              hashtag="RAPPGoodList"
              url={window.location.href}
            >
              <FacebookIcon size={50} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
              title={`Even 2020 had its silver linings! Check out my personal #RAPPGoodList for 2020, then make one of your own, Happy holidays!`}
              hashtags={['RAAPGoodList']}
              url={window.location.href}
            >
              <TwitterIcon size={50} round={true} />
            </TwitterShareButton>
            {/* <EmailShareButton
              subject="Happy Holidays from Rapp"
              body="https://www.rapp.com"
            >
              <EmailIcon size={50} round={true} />
            </EmailShareButton> */}
          </div>
          <CTA
            onClick={() => {
              // domtoimage
              //   .toJpeg(document.getElementById('download1'), {
              //     height: 1080,
              //     width: 1080,
              //   })
              //   .then(function (dataUrl1) {
              //     domtoimage
              //       .toJpeg(document.getElementById('download2'), {
              //         height: 1080,
              //         width: 1080,
              //       })
              //       .then(function (dataUrl2) {
              //         domtoimage
              //           .toJpeg(document.getElementById('download3'), {
              //             height: 1080,
              //             width: 1080,
              //           })
              //           .then(function (dataUrl3) {
              //             gifshot.createGIF(
              //               {
              //                 frameDuration: 7,
              //                 gifWidth: 1080,
              //                 gifHeight: 1080,
              //                 images: [dataUrl1, dataUrl2, dataUrl3],
              //               },
              //               function (obj) {
              //                 console.log(obj)
              //                 if (!obj.error) {
              //                   console.log(obj)
              //                   // var image = obj.image,
              //                   //   animatedImage = document.createElement('img')
              //                   // animatedImage.src = image
              //                   // document.body.appendChild(animatedImage)
              //                   var link = document.createElement('a')
              //                   link.download = 'Rapp-Holiday2020.gif'
              //                   link.href = obj.image
              //                   link.click()
              //                 }
              //               }
              //             )
              //           })
              //       })
              //   })
            }}
            className="cta--fluid card__cta"
          >
            {'Download Good List'}
          </CTA>
          <a
            href={'/'}
            className="cta cta--link cta--fluid cta--gray card__cta"
          >
            {'Make New List'}
          </a>
        </div>
      </div>
    </section>
  )
}

export default CardLanding
