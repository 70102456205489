import React, { useEffect, useState } from 'react'

import { UPDATE_CURRENT_STEP, UPDATE_USER } from '../../state/actionTypes'
import { useStateValue } from '../../state/state'

import Input from './Input'
import CTA from '../CTA'

import icon from '../../static/images/questions/mittens2.gif'

const question = 'What’s one positive word you want to take with you into 2021?'

const Question = () => {
  const [isValid, setIsValid] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [currentRadio, setCurrentRadio] = useState('')
  const [{ currentStep, user }, dispatch] = useStateValue()

  useEffect(() => {
    if (user.word.length > 0 || inputValue.length > 0) {
      setIsValid(true)
      setCurrentRadio(inputValue || user.word)
    } else {
      setIsValid(false)
    }
  }, [user, currentStep, inputValue, setCurrentRadio])

  return (
    <section className={'container question'}>
      <img className={'question__image'} src={icon} alt="" />
      <h3
        className={'heading-3 question__heading'}
      >{`Question ${currentStep}:`}</h3>
      <h4 className={'heading-4 question__question'}>{question}</h4>
      <Input
        type={'radio_4'}
        setInputValue={setInputValue}
        currentStep={currentStep}
        currentRadio={currentRadio}
      />
      <div className={'question__button-container'}>
        <CTA
          onClick={() => {
            setIsValid(false)
            dispatch({
              type: UPDATE_CURRENT_STEP,
              value: currentStep - 1,
            })
          }}
          className="question__button-container__button"
        >
          Back
        </CTA>
        <CTA
          onClick={() => {
            if (inputValue.length > 0) {
              dispatch({
                type: UPDATE_USER,
                value: {
                  ...user,
                  word: inputValue,
                },
              })
            }

            dispatch({
              type: UPDATE_CURRENT_STEP,
              value: currentStep + 1,
            })
          }}
          className="question__button-container__button"
          disabled={!isValid}
        >
          {currentStep === 8 ? 'Finish' : 'Next'}
        </CTA>
      </div>
    </section>
  )
}

export default Question
