import React from 'react'

const CTA = ({ children, className, onClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`cta ${className}`}
    >
      {children}
    </button>
  )
}

export default CTA
