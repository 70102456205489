import React from 'react'

import { UPDATE_CURRENT_STEP } from '../../state/actionTypes'
import { useStateValue } from '../../state/state'

import CTA from '../CTA'

import goodList from '../../static/images/GoodList.gif'

const Landing = () => {
  const [{ currentStep, user }, dispatch] = useStateValue()

  let greeting = 'Hey,'

  if (user.name) {
    greeting = `Hey ${user.name},`
  }

  return (
    <section className={'container landing'}>
      <h1 className="heading-1 landing__heading landing__heading--mobile">{`${greeting}\nlet’s celebrate the\ngood stuff`}</h1>
      <div className={'landing__hero'}>
        <img src={goodList} className="landing__image" alt="The Good List" />
      </div>
      <div className="landing__content">
        <h1 className="heading-1 landing__heading landing__heading--desktop">{`${greeting}\nlet’s celebrate\nthe good stuff`}</h1>
        <p className="paragraph landing__paragraph">
          2020 was a heck of a year. In times like these, the littlest good
          things&nbsp;mean&nbsp;a&nbsp;lot!
        </p>
        <p className="paragraph landing__paragraph">
          Take a few moments to answer 8 simple questions. We’ll take that and
          create your very own Good List to share with friends and family, or
          just keep the good stuff for yourself!
        </p>
        <CTA
          onClick={() => {
            dispatch({
              type: UPDATE_CURRENT_STEP,
              value: currentStep + 1,
            })
          }}
          className={'landing__cta'}
        >
          Show me what's good
        </CTA>
      </div>
    </section>
  )
}

export default Landing
