import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useStateValue } from '../../state/state'
import {
  LANDING_SCREEN,
  NAME_SCREEN,
  TREND_SCREEN,
  HOBBY_SCREEN,
  MEMORY_SCREEN,
  COMMUTE_SCREEN,
  CHARITY_SCREEN,
  WORD_SCREEN,
  THEME_SCREEN,
} from '../../constants/screens'
import { UPDATE_USER } from '../../state/actionTypes'

import Layout from '../../layout/Layout'
import Landing from '../../components/Landing'
import Question1 from '../../components/Question/Question1'
import Question2 from '../../components/Question/Question2'
import Question3 from '../../components/Question/Question3'
import Question4 from '../../components/Question/Question4'
import Question5 from '../../components/Question/Question5'
import Question6 from '../../components/Question/Question6'
import Question7 from '../../components/Question/Question7'
import Question8 from '../../components/Question/Question8'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Home = () => {
  const [{ currentStep, user }, dispatch] = useStateValue()
  const query = useQuery()

  useEffect(() => {
    if (query.get('fname') && user.name.length === 0) {
      dispatch({
        type: UPDATE_USER,
        value: {
          ...user,
          name: query.get('fname'),
        },
      })
    }
    if (query.get('em') && user.email.length === 0) {
      dispatch({
        type: UPDATE_USER,
        value: {
          ...user,
          email: query.get('em'),
        },
      })
    }
  }, [query, dispatch, user])

  switch (currentStep) {
    case LANDING_SCREEN:
      return (
        <Layout>
          <Landing />
        </Layout>
      )
    case NAME_SCREEN:
      return (
        <Layout>
          <Question1 />
        </Layout>
      )
    case TREND_SCREEN:
      return (
        <Layout>
          <Question2 />
        </Layout>
      )
    case HOBBY_SCREEN:
      return (
        <Layout>
          <Question3 />
        </Layout>
      )
    case MEMORY_SCREEN:
      return (
        <Layout>
          <Question4 />
        </Layout>
      )
    case COMMUTE_SCREEN:
      return (
        <Layout>
          <Question5 />
        </Layout>
      )
    case CHARITY_SCREEN:
      return (
        <Layout>
          <Question6 />
        </Layout>
      )
    case WORD_SCREEN:
      return (
        <Layout>
          <Question7 />
        </Layout>
      )
    case THEME_SCREEN:
    default:
      return (
        <Layout>
          <Question8 />
        </Layout>
      )
  }
}

export default Home
