import React from 'react'

const Progress = ({ current, total }) => {
  const renderedSteps = []
  let classes

  for (let i = 0; i < total; i++) {
    classes = 'step'

    if (current === i) {
      classes += ' step--active'
    } else if (i < current) {
      classes += ' step--complete'
    }

    renderedSteps.push(
      <li key={i} className={classes}>
        <span />
      </li>
    )
  }

  return <ol className={'progress'}>{renderedSteps}</ol>
}

export default Progress
