import logo from '../static/images/RAPP_Logo_RGB.png'

import React from 'react'

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="header__menu">
          <a
            href="https://www.rapp.com"
            target="_blank"
            rel="noopener noreferrer"
            className="header__rapp"
          >
            <span>HAPPY HOLIDAYS FROM</span>
            <img src={logo} className="header__rapp__logo" alt="Rapp Logo" />
          </a>
          <p className="header__hashtag">#RAPPGoodList</p>
        </div>
      </div>
    </header>
  )
}

export default Header
