export const LANDING_SCREEN = 0
export const NAME_SCREEN = 1
export const TREND_SCREEN = 2
export const HOBBY_SCREEN = 3
export const MEMORY_SCREEN = 4
export const COMMUTE_SCREEN = 5
export const CHARITY_SCREEN = 6
export const WORD_SCREEN = 7
export const THEME_SCREEN = 8
export const RESULTS_SCREEN = 9
